/* Transactions Page Data Table */
.ehBOst:not(:last-of-type) {
  border-bottom-style: none !important;
}

.dSCOua,
.ciHncy {
  display: flex;
  justify-content: end;
}

.cTwoiW,
.fIpSKG {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iLRTKp {
  min-width: 0;
}
